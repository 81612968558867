span.basket-count {
  height: 60px;
  text-align: right;
}

label.checkout {
  margin: 0px;
}

div.checkout-container {
  cursor: pointer;
  position: fixed;
  top: 120px;
  width: 90px;
  height: 90px;
  background-color: navy;
  color: white;
  border-radius: 50%;
  z-index: 1;
  left: calc(100vw - 110px);
}
